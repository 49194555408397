import React from 'react';
import { RichText } from 'prismic-reactjs';
import Slider from './slider';
import '../styles/awards/awards.scss'

const Awards = ({ awardsTitle, awards }) => {
  const sliderAwards = awards?.map((item, index) => ({
    id: index,
    name: item.award_name,
    image: item.award_image,
    sharp: item.award_imageSharp,
    link: item.award_website,
  }));
  return (
    <section className="section awards-section">
      <div className="container">
        <h3 className="title is-3 has-text-centered">
          {RichText.asText(awardsTitle)}
        </h3>
        <div className="columns is-centered">
          <div className="column is-12 awards-slider">
            <Slider
              className="swiper-award"
              slidesList={sliderAwards}
              layout="titleFirst"
              sliderId="awards"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Awards;