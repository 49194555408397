import React, { useState, useEffect } from "react"
import { RichText } from "prismic-reactjs"
import Image from "./image"
import { css } from "@emotion/core"

function Benefits({ benefits: { title, subtitle, benefits } }) {
  const [selectedBenefit, setSelectedBenefit] = useState(0)
  const [runBenefLoopPos, setRunBenefLoopPos] = useState(true)
  const totalBenefits = benefits.length - 1
  // const updateSelectedBenefit = idBenef => {
  //   if (!runBenefLoopPos) return false
  //   console.log(runBenefLoopPos);
  //   setSelectedBenefit(idBenef)
  // }
  useEffect(() => {
    let timer = () => {
      setTimeout(() => {
        selectedBenefit >= totalBenefits && runBenefLoopPos === true
          ? setSelectedBenefit(0)
          : setSelectedBenefit(selectedBenefit + 1)
      }, 3000)
    }
    if (runBenefLoopPos) {
      timer()
    } else {
      clearTimeout(timer)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [selectedBenefit, runBenefLoopPos, totalBenefits])

  const onSelectedBenefit = benefit => {
    // stop the looping of the benefits
    setRunBenefLoopPos(false)
    setSelectedBenefit(benefit)
  }
  return (
    <section className="section is-theme2" css={benefitsCss}>
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-6">
            <h3 className="title is-3 has-text-centered has-text-weight-semibold">
              {RichText.asText(title)}
            </h3>
            <p className="has-text-centered">{RichText.asText(subtitle)}</p>
          </div>
        </div>
        <div className="columns is-centered is-vcentered benefits__img">
          <div className="column is-5-desktop is-4-tablet is-12-mobile">
            {benefits[selectedBenefit].benefits_item_image && (
              <Image
                sharp={benefits[selectedBenefit].benefits_item_imageSharp}
                image={benefits[selectedBenefit].benefits_item_image}
              />
            )}
          </div>
          <div className="column is-7-desktop is-8-tablet is-12-mobile">
            {benefits.map((benefit, i) => {
              return (
                <button
                  key={benefit.benefits_id}
                  onClick={() => onSelectedBenefit(i)}
                  className={`button is-fullwidth benefits__button ${
                    i === selectedBenefit ? "active" : ""
                  }`}
                >
                  <span
                    className={"icon icon-" + benefit.benefits_id.toLowerCase()}
                  />
                  {benefit.benefits_item_name}
                </button>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Benefits

const benefitsCss = css`
.is-theme2 
{ 
  margin-top: 
  -5%
}
  .benefits__img {
    .gatsby-image-wrapper {
      height: 450px;
      width: auto;
      img {
        max-height: 450px;
      }
    }
  }
`
