import React from "react";
import Image from "./image";

export const ClientBanner = ({img, speed, data}) => {

    return (
        <section className="client_banner__container">
          <h1 className="client_banner__title">
            {data.clients_title[0].text}
          </h1>
          <div className="client_banner__wrapper">
            <section className="client_banner__list" style={{"--speed" : `${speed}s`}}>
              {img.map(client => (
                <a href={client.client_link.url} className="client_banner__logo" rel="nofollow">
                  <Image sharp={client.client_slideshow_slide_imageSharp} image={client.client_slideshow_slide_image} />
                </a>
              ))}
            </section>
            <section className="client_banner__list" style={{"--speed" : `${speed}s`}}>
              {img.map(client => (
                <a href={client.client_link.url} className="client_banner__logo" rel="nofollow">
                  <Image sharp={client.client_slideshow_slide_imageSharp} image={client.client_slideshow_slide_image} />
                </a>
              ))}
            </section> 
          </div>
        </section>
    )
}