import React, { useEffect, useState } from 'react'
import Image from './image';
import { RichText } from 'prismic-reactjs';
import linkResolver from '../utils/linkResolver';
import { IoClose } from "react-icons/io5";

export const DescModal = ({dot, updateStates, swipeActivate, textCoord}) => {

    const [isTextCoord, setIsTextCoord] = useState(false)

    const isImage = dot.feature_step_image ? true : false

    // Si il y a une image la modale est réhaussée, si la modale a un top de plus de ...% elle est + ou - réhaussée
    let positionModal = {
        top: dot.feature_step_image ? (parseInt(dot.y) >= 50 ? `${parseInt(dot.y) - 30}%` : `${parseInt(dot.y) - 5}%`) : `${parseInt(dot.y)}%`
    };

    // Si la position du dot orange est inferieur a 50% la modale sera a droite, et a gauche pour l'inverse / cela change le after également / maxWidth celon si il y a une image ou non
    if (parseInt(dot.x) < 50) {
        positionModal = {...positionModal , left : `${(parseInt(dot.x) + 5).toString()}%`, ':after' : {left: '-10px', top: dot.feature_step_image ? `${parseInt(dot.y) - 5}%` : '25%'}, maxWidth: isImage ? '625px' : '200px'}
    } else {
        positionModal = {...positionModal, right: `${(-parseInt(dot.x) + 105).toString()}%`, ':after' : {right: '-10px', top: dot.feature_step_image ? `${parseInt(dot.y) - 5}%` : '25%'}, maxWidth: isImage ? '625px' : '200px'}
    }

    const textModalIsLeft = parseInt(textCoord.textX ) <= 300 ? { right : '-5px'} : { left : '-5px'}

    useEffect(() => {
        if (textCoord.textX !== null && textCoord.textY !== null ) {
            setIsTextCoord(true)
        }
    }, [])


    return (
        <div className="desc_modal" css={positionModal}>
            <nav>
                <IoClose className={"icon-close"} size={20} onClick={() => {updateStates(false, !swipeActivate)}} />
            </nav>
            <section>
                {dot.feature_step_image && (
                    <Image
                        sharp={dot.feature_step_imageSharp}
                        image={dot.feature_step_image}
                    />
                )}
                <aside  className={isTextCoord ? 'desc_modal__text' : ''} 
                        css={{ top: textCoord.textY + 'px', left: textCoord.textX + 'px', ":after" : textModalIsLeft }}
                >
                        {dot.feature_step_content &&
                            RichText.render(
                                dot.feature_step_content,
                                linkResolver
                            )
                        }
                </aside>
            </section>
        </div>
    )
}